import WhyRhombusPage from "components/why-rhombus/WhyRhombusPage";
import React from "react";
import Helmet from "react-helmet";

import GlobalLayout from "../components/page/GlobalLayout";

export default function WhyRhombus() {
  return (
    <GlobalLayout color="var(--gradient-light)">
      <Helmet>
        <title>
          Why Rhombus - Smarter Physical Security for the Modern Enterprise
        </title>
        <meta
          name="description"
          content="Discover why school districts, commercial businesses, cities, and hospitals choose the Rhombus platform over a traditional DVR/NVR system."
        />
      </Helmet>
      <WhyRhombusPage />
    </GlobalLayout>
  );
}
