import {
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  MainParagraph,
  TitleMed,
  TitleSmall,
} from "components/rhombus-UI/theme/typography";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { css } from "linaria";
import React from "react";
import SlideInContainer from "./SlideInContainer";

const benefitRow = css`
  display: flex;
  gap: 2rem;
  width: 100%;
  @media (max-width: 820px) {
    flex-direction: column;
  }
`;

const flexCol = css`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: calc((100% -2rem) / 3);
  p {
    max-width: 437px;
  }
  @media (max-width: 820px) {
    width: 100%;
  }
`;

const imageStyles = css`
  width: 100% !important;
  border-radius: 10px !important;
`;

const DeviceBenefits = ({ data }) => {
  return (
    <SectionContainer>
      <SectionInner className="flex-col-start">
        <SlideInContainer>
          <TitleMed>{data.title}</TitleMed>
        </SlideInContainer>
        <div className={benefitRow}>
          {data.items.map(item => (
            <div className={flexCol} key={item.title}>
              <SlideInContainer>
                <GatsbyImage
                  image={getImage(item.image)}
                  alt={item.title}
                  className={imageStyles}
                  objectPosition="center"
                />
              </SlideInContainer>
              <TextContainer style={{ gap: "1rem" }}>
                <TitleSmall>{item.title}</TitleSmall>
                <MainParagraph>{item.description}</MainParagraph>
              </TextContainer>
            </div>
          ))}
        </div>
      </SectionInner>
    </SectionContainer>
  );
};

export default DeviceBenefits;
